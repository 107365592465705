@use "../../../../base/styles/general/settings" as settings;

$mediaPartners-header-title-background: settings.$white !default;
$mediaPartners-header-title-color: settings.$primary-blue !default;

$mediaPartners-title-color: settings.$primary-blue !default;
$mediaPartners-item-border-color: settings.$gray-placeholder !default;
$mediaPartners-item-hover-border-color: settings.$primary-blue !default;

$mediaPartners-watch-color: settings.$gray-secondary !default;
$mediaPartners-watch-hover-color: $mediaPartners-item-hover-border-color !default;

.header {
	height: 62px;
	margin: 40px 0;
	line-height: 62px;
	text-align: center;
	background-image: url('#{$default_dir}#{$mediaPartners-header-background-img}');
	background-repeat: repeat-x;
	background-position: center;
	background-size: contain;

	.title {
		display: inline-block;
		width: auto;
		padding: 0 20px;
		font-size: 28px;
		color: $mediaPartners-header-title-color;
		background-color: $mediaPartners-header-title-background;

		@include sf_pro_textsemibold;

		@include media-breakpoint-down(md) {
			font-size: 24px;
		}
	}
}

.row {
	margin: 0;

	.item {
		display: inline-table;
		flex: 0 0 32%;
		max-width: 31%;
		padding: 10px;
		margin: 1%;
		vertical-align: top;
		border: 1px solid $mediaPartners-item-border-color;

		@include media-breakpoint-down(md) {
			flex: 0 0 48%;
			max-width: 48%;
		}

		.cell {
			display: block;
			height: 100px;
			text-align: center;
			vertical-align: middle;

			img {
				width: auto;
				max-width: 85%;
				max-height: 100px;
				margin: 0 auto;
			}
		}

		&.videoNeighborhood .cell {
			height: 135px;

			img {
				max-height: 135px;
			}
		}

		.modalOpener {
			display: block;
			margin-top: 15px;
			font-size: 14px;
			line-height: 18px;
			color: $mediaPartners-watch-color;
			text-align: center;
			vertical-align: middle;
			cursor: pointer;

			i {
				font-size: 18px;
			}
		}

		&:hover {
			border: 1px solid $mediaPartners-item-hover-border-color;

			.modalOpener {
				color: $mediaPartners-watch-hover-color;
			}
		}
	}
}